import { render, staticRenderFns } from "./projectImportantList.vue?vue&type=template&id=2e4e39ce&scoped=true&"
import script from "./projectImportantList.vue?vue&type=script&lang=js&"
export * from "./projectImportantList.vue?vue&type=script&lang=js&"
import style0 from "./projectImportantList.vue?vue&type=style&index=0&id=2e4e39ce&prod&lang=scss&scoped=scoped&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e4e39ce",
  null
  
)

export default component.exports