<template>
	<el-dialog
		:title="!dataForm.recId ? '新增重要事项' : '修改重要事项'"
		:close-on-click-modal="false"
		:visible.sync="visible"
		width="1000px">
		<el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="80px" :inline="true">
			<el-row>
				<el-col :span="12">
					<el-form-item label="登记时间">
						<el-date-picker  class="selItemInput"
						  v-model="dataForm.registerTime"
						  type="date"
						  value-format="yyyy-MM-dd"
						  placeholder="选择日期">
						</el-date-picker>
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<el-form-item label="重要事项">
						<el-input class="selItemInput descAreaLg" type="textarea" rows="5" v-model="dataForm.importantContent" placeholder="请填写"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			  <el-row class="formBtnGroup">
				<el-button v-preventReClick class="primaryPlainBtn" type="primary" plain @click="visible = false">取消</el-button>
				<el-button v-preventReClick class="primaryPlainBtn" type="primary" @click="dataFormSubmit()">确定</el-button>
			  </el-row>
		</el-form>
	</el-dialog>
</template>

<script>
	import addImg from "@/assets/images/addImg.png"
	import $common from "@/utils/common.js"
	export default {
	  name: "importantList-add-or-update",
	  data() {
	    return {
			addImg: addImg,
			id: "",
			visible: true,
			action:"",
			form:{},
			subjectList:[],
			majorList:[],
			categoryList:[],
			dataForm:{
				workNo: $common.getItem("workNo"),
				recNo:"",
				importantContent:"",
				registerTime:"",
				registerMan:"",
			},
			dataRule:{

			}
	    };
	  },
	  components: {

	  },
	  activated() {

	  },
	  methods: {
		init(id) {
			this.dataForm = {
				workNo: $common.getItem("workNo"),
				recNo:"",
				importantContent:"",
				registerTime:"",
				registerMan:$common.getItem("userName"),
			};
			this.dataForm.recId = id?id:0;
			if(id){
				this.getDetail();
			}
		    this.$nextTick(() => {
		        this.visible = true;
		    })
		},
		getDetail(){
			this.$http({
			  url: this.$store.state.httpUrl + "/business/projectimportantinfo/info/"+this.dataForm.recId,
			  method: "get",
			  params: {},
			}).then(({ data }) => {
			  if (data && data.resultCode === 200) {
				  this.dataForm = data.body;
				  if (!this.dataForm.registerMan){
					  this.dataForm.registerMan = $common.getItem("userName");
				  }
			  }
			});
		},
		dataFormSubmit() {
		  this.$refs["dataForm"].validate(valid => {
		    if (valid) {
			  let _url = "/business/projectimportantinfo/save";
			  if(this.dataForm.recId && this.dataForm.recId != 0){
				_url = "/business/projectimportantinfo/update";
			  }
		      this.$http({
		        url: this.$store.state.httpUrl + _url,
		        method: "post",
		        data: this.$http.adornData(this.dataForm)
		      }).then(({ data }) => {
		        if (data && data.resultCode === 200) {
		          this.$message({
		            message: "操作成功",
		            type: "success",
		            duration: 1500,
		            onClose: () => {
		              this.visible = false;
		              this.$emit("refreshDataList");
		            }
		          });
		        } else {
		          this.$message.error(data.msg);
		        }
		      });
		    }
		  });
		}
	  }
	};
</script>

<style lang="scss" scoped="scoped">
	.selItemInput.descAreaLg{
		width: 718px !important;
	}
	.addImgBox{
		width: 136px;
		height: 136px;
		display: inline-block;
		vertical-align: middle;
		margin-right: 10px;
	}
</style>
